<!--
 * @Author: your name
 * @Date: 2022-04-11 14:41:00
 * @LastEditTime: 2022-04-11 14:41:03
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/salesReconciliation/accountStatement/components/writeOffInformation.vue
-->

<template>
  <TableWithPagination :tableData="targetLists"
    max-height='400px'
    :columnList="columnList"
  ></TableWithPagination>
</template>

<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { getWriteOffInformationList } from '@/api/accountStatement';
export default {
  name: 'WriteOffInformation',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  methods: {
    initPage () {
      let currentParams = {
        id: this.$route.query.id,
      };
      getWriteOffInformationList(currentParams).then(({body}) => {
        this.targetLists = body || [];
      });
    },
  },
  data () {
    return {
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '交易流水',
          minWidth: '150px',
          prop: 'tradeSerialNumber',
        },
        {
          tooltip: true,
          label: '核销时间',
          minWidth: '150px',
          prop: 'createTime',
        },
        {
          tooltip: true,
          label: '付款/退款金额',
          minWidth: '150px',
          prop: 'payRefundAmount',
        },
        {
          tooltip: true,
          label: '关联银行流水',
          minWidth: '180px',
          prop: 'bankSerialNumber',
        },
        {
          tooltip: true,
          label: '操作人',
          minWidth: '120px',
          prop: 'createUserName',
        },
        {
          tooltip: true,
          label: '关联申请单',
          minWidth: '180px',
          prop: 'orderType',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
