<!--
 * @Author: your name
 * @Date: 2022-04-11 14:36:40
 * @LastEditTime: 2022-04-11 14:36:44
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/salesReconciliation/accountStatement/components/paymentInformation.vue
-->
<template>
  <TableWithPagination
    :tableData="targetLists"
    :columnList="columnList"
    max-height='400px'
    :pagination.sync="pagination"
    @current-change="getTargetLists"
    @sort-change="getTargetLists"
  ></TableWithPagination>
</template>

<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { getPaymentInformationLists } from '@/api/accountStatement';
export default {
  name: 'PaymentInformation',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  methods: {
    initPage () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        orderId: this.$route.query.id,
        reconciliationId: this.$route.query.id,
        id: this.$route.query.id,
        pageFlag: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      getPaymentInformationLists(currentParams).then(({body}) => {
        this.targetLists = body.list || [];
        this.pagination.total = body.total || 0;
      });
    },
  },
  data () {
    return {
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '退/付款申请人',
          minWidth: '150px',
          prop: 'payApplicant',
        },
        {
          tooltip: true,
          label: '付款申请时间',
          minWidth: '150px',
          prop: 'payApplicantTime',
        },
        {
          tooltip: true,
          label: '付款类型',
          minWidth: '100px',
          prop: 'payMatter',
          format: val => val === 1 ? '订单付款' : '合同预付',
        },
        {
          tooltip: true,
          label: '付款申请金额',
          minWidth: '150px',
          prop: 'planPayAmout',
        },
        {
          tooltip: true,
          label: '实付金额',
          minWidth: '150px',
          prop: 'payAmout',
        },
        {
          tooltip: true,
          label: '关联流水',
          minWidth: '150px',
          prop: 'hpsId',
        },
        {
          tooltip: true,
          label: '付款人',
          minWidth: '100px',
          prop: 'drawee',
        },
        {
          tooltip: true,
          label: '付款时间',
          minWidth: '150px',
          prop: 'payApplicantTime',
        },
        {
          tooltip: true,
          label: '关联单据',
          minWidth: '150px',
          prop: 'orderCode',
        },
        {
          tooltip: true,
          label: '付款申请状态',
          minWidth: '150px',
          prop: 'payStsStr',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
